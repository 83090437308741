import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import { useScrollTrigger } from "@material-ui/core";
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const csvHeader=["Username","Sign Up Date", "Plan","Time Spent", "Videos Created", "Documents Created","Videos Viewed","Documents Viewed", "Shared Videos", "Shared Documents","Visits","Invites"]


const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [users,setUsers]=React.useState([]);
  const [textValue,setText]=React.useState('');
  const [startDate, setStartDate] = React.useState(new Date());

  const [filteredUsers,setFilteredUsers]=React.useState([]);

  const [signUpFilter,setSignUpFilter]=React.useState('ascending');
  
  const [createdFilter,setCreatedFilter]=React.useState('ascending');
  
  const [addedFilter,setAddedFilter]=React.useState('ascending')
  
  const [viewedFilter,setViewedFilter]=React.useState('ascending')

  const [timeSpentFilter,setTimeSpentFilter]=React.useState('ascending')

  const [loadingUsers,setLoadingUsers]=React.useState(true);

  
  function sortByFilter(filter){
    if (filter=='signUp'){
      let filteredUsers=[]
      if (signUpFilter=='ascending'){

        filteredUsers=users.sort((a,b)=>{return new Date(b[1]) - new Date(a[1])});
        setSignUpFilter('descending')
      }
      else{
        filteredUsers=users.sort((a,b)=>{return new Date(a[1]) - new Date(b[1])})
        setSignUpFilter('ascending')

      }
      setFilteredUsers(filteredUsers)
      
    }
    else if (filter=='timeSpent'){
      let filteredUsers=[]
      if (timeSpentFilter=='ascending'){

        filteredUsers=users.sort((a,b)=>{return b[3] - a[3]});
        setTimeSpentFilter('descending')
      }
      else{
        filteredUsers=users.sort((a,b)=>{return a[3] - b[3]})
        setTimeSpentFilter('ascending')

      }
      setFilteredUsers(filteredUsers)
      
    }

  }

  async function fetchUsers() {
    fetch("https://backendmobile.onrender.com/metrics/allUsers", {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res=>res.json())
    .then(res=>{
      let users=res.users
      let active_users=[]
      console.log(users.length)
      for (let i=0;i<users.length;i++){
        if (users[i].metricsAllTime){
          const userMetrics=users[i].metricsAllTime
          active_users.push([users[i].username,new Date(userMetrics.accountCreated).toDateString(),users[i].isFree?'Free':'Pro',Math.floor(userMetrics.timeSpent/(60000)),userMetrics.videosCreated,userMetrics.summariesCreated,userMetrics.videosViewed,userMetrics.documentsViewed,userMetrics.sharedVideos,userMetrics.sharedSummaries,userMetrics.visits,userMetrics.invites])
      
        }
        }
      
      setLoadingUsers(false)
      setUsers(active_users)
    })
  }
  React.useEffect(() => {
    fetchUsers();
  },[]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>

          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>ALL USERS</h4>
            <p className={classes.cardCategoryWhite}>
              {users.length} Total Users
            </p>
          </CardHeader>
          {!loadingUsers &&
          <div>
          <div style={{flexDirection:'row',margin:20}} >
          Sort By:
          <Button style={{marginLeft:20}} variant='contained'  onClick={()=>sortByFilter('signUp')} color="primary">
          Sign Up Date
          </Button>
          <Button style={{marginRight:10,marginLeft:10}}  variant='contained' onClick={()=>sortByFilter('timeSpent')} color="primary">
            Time Spent
          </Button>
          <Button  variant='contained' onClick={()=>sortByFilter('created')} color="primary">
            Total Files Created
          </Button>
          <Button style={{marginRight:10,marginLeft:10}}  variant='contained' onClick={()=>sortByFilter('viewed')} color="primary">
            Total Files Viewed
          </Button>
          <Button  variant='contained' onClick={()=>sortByFilter('shared')} color="primary">
            Total Files Shared
          </Button>

          </div>
          <CSVLink style={{marginLeft: 20,color:'greenyellow'}} filename={"user_data.csv"} headers={csvHeader} data={filteredUsers}>EXPORT TO CSV</CSVLink>

          </div>

          }
          <div style={{flexDirection:'row'}}>
          <input style={{margin:20,fontSize:'16px'}} placeholder='Search' type="text" value={textValue} 
                    onChange={(e)=>{
                      setText(e.target.value)
                      setFilteredUsers(users.filter((user)=>user[0].includes(textValue)))
                    }} />
                        Select Date: <DatePicker style={{margin:20}} selected={startDate} 
                        onChange={date =>{ 
                          setStartDate(date)
                          setFilteredUsers(users.filter((user)=>{return new Date(user[1])>date}))

                          }} />
          </div>


          <CardBody>
            {!loadingUsers &&
            
            <Table
              tableHeaderColor="primary"
              tableHead={["Username","Sign Up Date", "Plan","Time Spent", "Videos Created", "Documents Created","Videos Viewed","Documents Viewed", "Shared Videos", "Shared Documents","Visits","Invites"]}
              tableData={filteredUsers}
            />
            }
          </CardBody>
        </Card>
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Table on Plain Background
            </h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Country", "City", "Salary"]}
              tableData={[
                ["1", "Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
                ["2", "Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
                ["3", "Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
                [
                  "4",
                  "Philip Chaney",
                  "$38,735",
                  "Korea, South",
                  "Overland Park"
                ],
                [
                  "5",
                  "Doris Greene",
                  "$63,542",
                  "Malawi",
                  "Feldkirchen in Kärnten"
                ],
                ["6", "Mason Porter", "$78,615", "Chile", "Gloucester"]
              ]}
            />
          </CardBody>
        </Card>
      </GridItem> */}
    </GridContainer>
  );
}
