import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import { useScrollTrigger } from "@material-ui/core";
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function SessionList() {
  const classes = useStyles();
  const [sessions,setSessions]=React.useState([]);
  const [textValue,setText]=React.useState('');
  const [startDate, setStartDate] = React.useState(new Date());
  const [startTime, setStartTime] = React.useState(new Date());


  const [filteredSessions,setFilteredSessions]=React.useState([]);

  const [signUpFilter,setSignUpFilter]=React.useState('ascending');
  const [timeSpentFilter,setTimeSpentFilter]=React.useState('ascending')

  const [loadingSessions,setLoadingSessions]=React.useState(true)

  function sortByFilter(filter){
    if (filter=='signUp'){
      let filteredUsers=[]
      if (signUpFilter=='ascending'){

        filteredUsers=sessions.sort((a,b)=>{return new Date(b[2]) - new Date(a[2])});
        setSignUpFilter('descending')
      }
      else{
        filteredUsers=sessions.sort((a,b)=>{return new Date(a[2]) - new Date(b[2])})
        setSignUpFilter('ascending')

      }
      setFilteredSessions(filteredUsers)
      
    }
    else if (filter=='timeSpent'){
      let filteredUsers=[]
      if (timeSpentFilter=='ascending'){

        filteredUsers=sessions.sort((a,b)=>{return b[1] - a[1]});
        setTimeSpentFilter('descending')
      }
      else{
        filteredUsers=sessions.sort((a,b)=>{return a[1] - b[1]})
        setTimeSpentFilter('ascending')

      }
      setFilteredSessions(filteredUsers)
      
    }

  }

  async function fetchSessions() {
    fetch("https://backendmobile.onrender.com/metrics/allSessions", {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res=>res.json())
    .then(res=>{
      let sessions=res.sessions
      let active_users=[]
      console.log(sessions.length)
      for (let i=0;i<sessions.length;i++){
          console.log(sessions[i].sessionStart)
          active_users.push([sessions[i].username,sessions[i].timeSpent,new Date(sessions[i].sessionStart).toDateString(),new Date(sessions[i].sessionEnd).toDateString(),new Date(sessions[i].sessionStart).toLocaleTimeString(),new Date(sessions[i].sessionEnd).toLocaleTimeString(),sessions[i].sharedVideos.count,sessions[i].sharedSummaries.count,sessions[i].videosCreated.count,sessions[i].summariesCreated.count,sessions[i].videoSearches.count,sessions[i].invites.count])
        }
      
        setLoadingSessions(false)
      setSessions(active_users)
    })
  }

  React.useEffect(() => {
    
    fetchSessions();
  },[]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>ALL SESSIONS</h4>
            <p className={classes.cardCategoryWhite}>
              {sessions.length} Total Sessions
            </p>
          </CardHeader>
          <CardBody>
          {!loadingSessions &&
          <div>
          <div style={{flexDirection:'row',margin:20}} >
          Sort By:
          {/* <Button style={{marginLeft:20}} variant='contained'  onClick={()=>sortByFilter('startDate')} color="primary">
          Start Date
          </Button>
          <Button  variant='contained' onClick={()=>sortByFilter('startTime')} color="primary">
            Start Time
          </Button> */}
          <Button style={{marginRight:10,marginLeft:10}}  variant='contained' onClick={()=>sortByFilter('timeSpent')} color="primary">
            Time Spent
          </Button>

          <Button style={{marginRight:10,marginLeft:10}}  variant='contained' onClick={()=>sortByFilter('viewed')} color="primary">
            Total Files Viewed
          </Button>
          <Button  variant='contained' onClick={()=>sortByFilter('shared')} color="primary">
            Total Files Shared
          </Button>

          </div>
          <CSVLink style={{marginLeft: 20,color:'greenyellow'}} filename={"session_data.csv"} headers={["Username","Duration", "Start Date", "End Date","Start Time","End Time", "Shared Videos","Shared Articles","Videos Created","Articles Created","Video Searches","Invites"]} data={filteredSessions}>EXPORT TO CSV</CSVLink>

          </div>

          }
                    <input style={{margin:20,fontSize:'16px'}} placeholder='Search' type="text" value={textValue} 
                    onChange={(e)=>{
                      setText(e.target.value)
                      setFilteredSessions(sessions.filter((session)=>session[0].includes(textValue)))
                    }} />

                        {/* Pick a Date<DatePicker style={{margin:20}} selected={startDate} 
                        onChange={date =>{ 
                          setStartDate(date)
                          setFilteredSessions(sessions.filter((user)=>{return new Date(user[2])>date}))

                          }} /> */}
                          Select Date/Time: <DatePicker
                        selected={startTime}
                        onChange={date =>{ 
                            setStartTime(date)
                            setFilteredSessions(sessions.filter((user)=>{return new Date(user[2])>date}))
  
                            }}
                        showTimeSelect
                        dateFormat="Pp"
                        />
              {!loadingSessions && 
              
            <Table
            tableHeaderColor="info"
            tableHead={["Username","Duration", "Start Date", "End Date","Start Time","End Time", "Shared Videos","Shared Articles","Videos Created","Articles Created","Video Searches","Invites"]}
            tableData={filteredSessions}
          />
              
              }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
