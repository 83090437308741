import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";

import { PieChart } from 'react-minimal-pie-chart';


import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const getRandomColor=()=> {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [siteMetrics, setSiteMetrics] = React.useState([]);
  const [rawMetrics,setRawMetrics]=React.useState([]);

  const [createdMetrics,setCreatedMetrics]=React.useState([])
  const [viewedMetrics,setViewedMetrics]=React.useState([])
  const [sharedMetrics,setSharedMetrics]=React.useState([])


  const [activeUsers, setActiveUsers] = React.useState([]);
  const [activityToday,setActivityToday] =React.useState([]);
  const [bundledMetrics,setBundledMetrics] = React.useState([])
  const [loadingUsers,setLoadingUsers]=React.useState(true)
  const [loadingAcitivityToday,setLoadingActivityToday]=React.useState(true)


  function bundleMetrics(bundleMetric,overallMetrics,value,subMetric){
    let metricObject={};
    if (bundledMetrics.includes(bundleMetric)){

      for (let j=0;j<overallMetrics.length;j++){
        if (overallMetrics[j].header==bundleMetric){
          overallMetrics[j].value+=('\n'+subMetric+': '+value)
        }
      }
      return overallMetrics
    }
    else {
      bundledMetrics.push(bundleMetric);
      metricObject={
        header:bundleMetric,
        value:subMetric+': '+value
      }
      overallMetrics.push(metricObject);
      return overallMetrics
      } 
  }

  async function fetchSiteMetrics() {
    fetch("https://backendmobile.onrender.com/metrics/activity", {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res=>res.json())
    .then(res=>{
      // console.log(res);
      let metrics=res.metrics
      setRawMetrics(metrics)
      let overallMetrics=[];
      for (let i=0;i<res.metrics.length;i++){
        for (let x in res.metrics[i]){
          if (x!='_id' && x!='__v' && x!='name' && x!='activeUsers' && !x.toLowerCase().includes('folder')
          && !x.includes('payment') && !x.includes('useCase')
          ){
            if(x.includes('Created')){
              overallMetrics=bundleMetrics('Created',overallMetrics,Math.floor(res.metrics[i][x]),x);
              if (res.metrics[i][x]!=0){
                let metrics=createdMetrics;
                metrics.push({title:x,value:res.metrics[i][x],color:getRandomColor()})
                setCreatedMetrics(metrics)
              }
              }
          else if (x.includes('are')){
          overallMetrics=bundleMetrics('Shares',overallMetrics,Math.floor(res.metrics[i][x]),x);
          if (res.metrics[i][x]!=0){
            let metrics=sharedMetrics;
            metrics.push({title:x,value:res.metrics[i][x],color:getRandomColor()})
            setSharedMetrics(metrics)
          }
          }
          else if (x.includes('Viewed')){
            overallMetrics=bundleMetrics('Viewed',overallMetrics,Math.floor(res.metrics[i][x]),x);
            if (res.metrics[i][x]!=0){
              let metrics=viewedMetrics;
              metrics.push({title:x,value:res.metrics[i][x],color:getRandomColor()})
              setViewedMetrics(metrics)
            }
            }
            else{
              let metricObject={};
              metricObject={
                header:x,
                value:x.toLowerCase().includes('timespent')?Math.floor(res.metrics[i][x]/3600000)+' Hours':Math.floor(res.metrics[i][x])
              }
              overallMetrics.push(metricObject);
              }
          }
        }
      }
      setSiteMetrics(overallMetrics)
      console.log(overallMetrics.length)
    })
  }
  async function fetchActiveUsers() {
    fetch("https://backendmobile.onrender.com/metrics/activeUsers", {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res=>res.json())
    .then(res=>{
      let users=res.users
      let active_users=activeUsers;
      for (let i=0;i<users.length;i++){
        console.log(users[i].metricsAllTime)
        active_users.push([users[i].username,new Date(users[i].metricsAllTime.accountCreated).toDateString(),users[i].isFree?'Free':'Pro'])
      }
      setLoadingUsers(false)
      setActiveUsers(active_users)
    })
  }
  async function fetchActivityToday() {
    fetch("https://backendmobile.onrender.com/metrics/activityToday", {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res=>res.json())
    .then(res=>{
      let users=res.users
      let active_users=activeUsers;
      for (let i=0;i<users.length;i++){
        console.log(users[i].metricsAllTime)
        active_users.push([users[i].username,new Date(users[i].metricsAllTime.accountCreated).toDateString(),users[i].isFree?'Free':'Pro'])
      }
      setLoadingActivityToday(false)
      loadingAcitivityToday(active_users)
    })
  }

  React.useEffect(() => {
    fetchSiteMetrics();
    fetchActiveUsers();
    // fetchActivityToday();

    // setInterval(()=>{
    //   fetchActivityToday();
    // },30000)
  },[]);

  return (
    <div>
      <GridContainer>
        {siteMetrics.map((l,k)=>
                  <GridItem key={k} xs={8} sm={4} md={2}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      <h3  className={classes.cardHeader}>{l.header}</h3>
                      <p className={classes.cardTitle}>
                        {l.value}
                      </p>
                    </CardHeader>
                  </Card>
                </GridItem>
        
        )}

        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Used Server Space</p>
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Stripe Revenue</p>
              <h3 className={classes.cardTitle}>$34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Fixed Issues</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Twitter Followers</p>
              <h3 className={classes.cardTitle}>+245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      <div style={{flexDirection:'row'}} >

     Created: <PieChart
      style={{height:300}}
      data={createdMetrics}
/>
      Viewed: <PieChart
      style={{height:300}}
      data={viewedMetrics}
/>
      Shared: <PieChart
      style={{height:300}}
      data={sharedMetrics}
/>
      </div>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sign Ups</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sign ups.
              </p> *
            </CardBody>
             <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter> *
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Visits</h4>
               <p className={classes.cardCategory}>Last Campaign Performance</p> 
            </CardBody>
             <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter> 
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>User Activity (Today)</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p> 
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter> 
          </Card>
        </GridItem>
      </GridContainer> */}
      
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "Bugs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                )
              }
            ]}
          />
        </GridItem> */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Active Users</h4>
              <p className={classes.cardCategoryWhite}>
                {activeUsers.length} Users Currently Active
              </p>
            </CardHeader>
            <CardBody>
              {!loadingUsers &&
              
              <Table
                tableHeaderColor="warning"
                tableHead={["Username", "Sign Up Date", "Plan"]}
                tableData={activeUsers}
              />
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
